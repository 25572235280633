import { Component, ElementRef, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GridsterItem } from 'angular-gridster2';
import { DashboardQueryInterface, VisualType } from './bhive-dashboard-config.interface';
import { BhiveDashboardService } from './bhive-dashboard.service';
import { Chart, ChartConfiguration } from 'chart.js';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DrillDownDialog } from './drilldown-dialog.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import * as d3 from 'd3';
import annotationPlugin from 'chartjs-plugin-annotation';
import { BaseChartDirective } from 'ng2-charts';
import { BhiveDashboardItem } from './bhive-dashboard-item.interface';
import { HttpClient } from '@angular/common/http';

Chart.register(annotationPlugin);
interface TreeNode {
  parent: DashboardQueryInterface;
  level: number;
  filterName: string;
  children?: TreeNode[];
  filterby: string
}

interface GeoJSONFeature {
  type: string;
  properties: {
    // Assuming there is an 'id' property in the GeoJSON features
    id: string;
  };
  geometry: any; // Modify this based on the actual structure of your GeoJSON features
}

export interface OttoComment {
  supplierName: string,
  supplierId: number,
  commentDetails: [
    {
      createdOn: Date,
      userName: string,
      comment: string
    }
  ],
  queryId: number
}

@Component({
  selector: 'comment-dialog',
  templateUrl: `comment-dialog.component.html`,
  styleUrls: [`bhive-dashboard.scss`],
})
export class CommentDialog {
  comment: string = "";
  constructor(
    public dialogRef: MatDialogRef<CommentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: OttoComment, private http: HttpClient, private cubejs: BhiveDashboardService,
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  publish() {
    let model = {
      supplierId: this.data.supplierId,
      comment: this.comment,
      queryId: this.data.queryId
    };
    this.http.post<any>(this.cubejs.baseHref + '/saveAnalyticsComment', model)
      .subscribe((res: any) => {
        // this.toastService.success("Your changes save successfully")
        this.dialogRef.close(res['comment']);
      });
  }
}

@Component({
  selector: 'bhive-dashboard-item',
  templateUrl: 'bhive-dashboard-item.component.html',
  styleUrls: [`bhive-dashboard.scss`],
})
export class BhiveDashboardItemComponent implements OnChanges {
  @ViewChild(BaseChartDirective) myChart: BaseChartDirective;
  @ViewChild('worldMap', { static: false }) private mapContainer!: ElementRef;
  @Input() id: string;
  @Input() fullItem: BhiveDashboardItem;
  @Input() data: DashboardQueryInterface;
  @Input() filters: Array<any>;
  @Input() visualCode: string;
  @Input() width?: number;
  @Input() height?: number;
  @Input() organizationId?: number;
  fontSize = Math.min(this.width! + 20, this.height!) * 0.5;
  public showDrilUp: boolean = false;
  // Using a tree structure to represent the hierarchy
  drillHierarchy: TreeNode[] = [];
  currentDrillNode: TreeNode | undefined;
  drillFilters: Array<any> = [];
  drilledDownQuery: DashboardQueryInterface;
  private svg: any;
  private projection: any;
  private path: any;
  public chartLabels = [];
  public chartLabelsLong = [];
  public chartColors = [];
  public chartType: any = '';
  public chartLegend = true;
  public chartData = [];
  public gaugeChartData: any;
  public gaugeChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    // aspectRatio: 1,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      },
    }
  };
  public gaugeChartText = [{
    id: 'gaugeChartText',
    afterDatasetsDraw(chart: any, args: any, pluginOptions: any) {
      const { ctx, data, chartArea: { top, bottom, left, right, width, height }, scales: { r }, canvas } = chart;
      ctx.save();

      const xCoor = chart.getDatasetMeta(0).data[0].x;
      const yCoor = chart.getDatasetMeta(0).data[0].y;
      const score = data.datasets[0].data[0];
      let rating;
      if (score < 50) { rating = 'Not Good'; }
      if (score >= 50 && score < 80) { rating = 'Good'; }
      if (score >= 80) { rating = 'Excellent'; }

      function textLabel(text: any, x: any, y: any, fontSize: any, textBaseLine: any, textAlign: any) {
        ctx.font = `${fontSize}px sans-serif`;
        ctx.fillStyle = '#666';
        ctx.textBaseline = textBaseLine;
        ctx.textAlign = textAlign;
        ctx.fillText(text, x, y);

      }
      // textLabel('0', left+5, yCoor, Math.min(xCoor, yCoor) * 0.05, 'bottom', 'left');
      // textLabel('100', right-5, yCoor, Math.min(xCoor, yCoor) * 0.05, 'bottom', 'right');
      textLabel([parseFloat(score).toFixed(2), '%'].join(''), xCoor, yCoor, Math.min(xCoor, yCoor) * 0.3, 'bottom', 'center');
      textLabel(rating, xCoor, yCoor - (Math.min(xCoor, yCoor) * 0.32), Math.min(xCoor, yCoor) * 0.1, 'bottom', 'center');

    }
  }];
  resultSet: any;
  dtl: Array<any>;
  gridItem: GridsterItem;
  VisualTypes = VisualType

  @ViewChild('tableMatPaginator') tableMatPaginator: MatPaginator;
  @ViewChild('tableMatSort') tableMatSort: MatSort;
  tableDatasource: MatTableDataSource<any> = new MatTableDataSource<any>();
  tableColumns: any[] = [];
  tableColumnsNames: any[] = [];
  clickableColumns: any[] = [];

  mainValueCard: number;
  xValue: number;
  descriptionCard: string;
  descriptionCardTooltip: string
  format: string
  isLoadingChart = false; // Add this flag
  isCommentColumnPresent = false;

  constructor(private cubejs: BhiveDashboardService, private dialog: MatDialog) { }
  [propName: string]: any;
  //TODO:Refactor this class for better readability and reusable functionality
  ngOnChanges(changes: SimpleChanges): void {
    this.fontSize = Math.min(this.width! + 20, this.height!) * 0.5;
    if ('filters' in changes || 'data' in changes) {
      // Coerce the value to a boolean
      if ('filters' in changes)
      { this.filters = changes['filters'].currentValue;
        this.drillFilters = [...this.filters];
      }
      if ('data' in changes)
        this.data = changes['data'].currentValue;
      this.getData();
    }
  }
  drop(event: CdkDragDrop<string[]> | any) {
    moveItemInArray(this.tableColumnsNames, event.previousIndex, event.currentIndex);
  }
  public chartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: true, position: 'top', labels: {
          font: { size: 10, }, boxWidth: 10,
        },
      },
      tooltip: {
        enabled: true,
        borderWidth: 1,
        callbacks: {
          title: (ctx: any) => {
            // Return the full label text for the tooltip
            return this.chartLabelsLong[ctx[0].dataIndex]
          }
        } as any
      },
    },
    maintainAspectRatio: false,
    layout: { padding: 0 },
  };
  chart: Chart;

  private getData() {

    this.isLoadingChart = true;
    this.cubejs.getChartData(this.data, this.visualCode, this.filters, this.organizationId).then(dt => {
      // if (dt.result.chartData.length != 0) {
      this.drilledDownQuery = this.data;
      this.getTarget().then(() => {
        this.drillHierarchy.push({
          parent: this.data,
          level: 0,
          filterName: "",
          children: [],
          filterby: ""
        });

        this.currentDrillNode = this.drillHierarchy[0];
        if (this.visualCode == VisualType.Table) {
          let dataTable = this.cubejs.translateColumns(dt.result, this.tableColumns, this.drilledDownQuery);
          this.tableColumns = dataTable.columns;
          this.clickableColumns = dataTable.clickableColumns;
          this.tableColumnsNames = dataTable.columns.map((column: any) => column.name);
          this.tableColumnsNames = this.tableColumnsNames.filter(column => column !== "hasComment");
          this.isCommentColumnPresent = this.tableColumns.some(col => col.name === 'comment')
          this.tableDatasource.data = dataTable.data;
          this.tableDatasource = new MatTableDataSource<any>(dataTable.data);
          this.tableDatasource.paginator = this.tableMatPaginator;
          this.tableDatasource.sort = this.tableMatSort;
          const meta = dt.meta;
          this.tableColumns.forEach((tableColumn: any) => {
            const columnMeta = meta.find((column: any) => column.key === tableColumn.key);

            if (columnMeta && columnMeta.meta) {
              // Add a 'description' property to the column
              tableColumn.description = columnMeta.meta.description;
            }
          });
        }
        else if (this.visualCode == VisualType.NumberCard) {
          if (dt.result[0] === null)
            this.mainValueCard = 0
          else {
            this.mainValueCard = dt.result[0].numericValues;
            this.xValue = dt.result[0].xValue
          }
          this.descriptionCard = dt.result[0].yValue;
          this.resultSet = dt.data;
          const meta = dt.meta;
          this.format = meta[0].format;
          if (meta[0].key == this.descriptionCard[0] && meta[0].meta) {
            // Use the title or any other property from meta as the tooltip content
            this.descriptionCardTooltip = meta[0].meta.description;
          }
        }
        else if (this.visualCode == VisualType.Gauge) {
          this.chartType = dt.result['chartType'];
          this.chartLabels = dt.result['chartLabels'];
          this.chartData = dt.result['chartData'];
          this.resultSet = dt.data;
          this.createGaugeChart();
        }
        else if (this.visualCode == VisualType.WorldMap) {
          this.chartType = dt.result['chartType'];
          this.chartLabels = dt.result['chartLabels'];
          this.chartData = dt.result['chartData'];
          this.resultSet = dt.data;
          this.createWorldMapChart();
        }
        else {
          if (this.visualCode == VisualType.StackedBarChart) {
            this.chartOptions!.scales = {
              x: {
                stacked: true,
              },
              y: {
                stacked: true
              }
            }
          }
          let hovering = false;
          this.data.Name = this.data.Name.split('/')[0];
          this.chartType = dt.result['chartType'];
          this.chartLabels = dt.result['chartLabels'].map((label: any) => label.short);
          this.chartLabelsLong = dt.result['chartLabels'].map((label: any) => label.long);
          this.chartData = dt.result['chartData'];
          this.chartData = dt.result['chartData'].map((dataItem: any) => ({
            ...dataItem,
            label: this.formatChartLabel(dataItem.label)
          }));
          this.resultSet = dt.data;
          let tooltips = this.chartLabelsLong
          if (this.chartOptions && this.chartOptions.plugins) {
            const queryName = this.data
            this.chartOptions.plugins.legend = {
              onHover: function (event: any, legendItem: any) {
                const tooltipId = 'tooltip_' + queryName['Name'] + queryName['VisualType']; // Generate dynamic tooltip id
                let tooltip = document.getElementById(tooltipId) as HTMLElement;
                hovering = true;
                if (tooltip) {
                  tooltip.innerHTML = legendItem.index || legendItem.index == 0 ? tooltips[legendItem.index] : legendItem.text;
                  tooltip.style.left = event.x + "px";
                  tooltip.style.top = event.y + "px";
                  tooltip.style.visibility = 'visible';
                }
              },
              onLeave: function () {
                const tooltipId = 'tooltip_' + queryName['Name'] + queryName['VisualType']; // Generate dynamic tooltip id
                let tooltip = document.getElementById(tooltipId) as HTMLElement;
                hovering = false;
                if (tooltip) {
                  tooltip.innerHTML = "";
                  tooltip.style.visibility = 'hidden';
                }
              }
            }

          } else {
            // chartOptions.plugins.legend is undefined
          }

        }
        this.isLoadingChart = false;
      })
        .catch(err => { this.isLoadingChart = false; });
      // }
      // else { this.isLoadingChart = false; }
    })
      .catch(error => {
        console.error('An error occurred:', error);
        this.isLoadingChart = false;
      });
  }

  isEmptyChart(data: any, chartType: any) {
    switch (chartType) {
      case 'table':
        return !data || Object.keys(data).length === 0;
      case 'number_card':
        return !data && data != 0;
      case 'gauge':
        return !data && data != 0;
      case 'chart':
        return !data.some((item: any) => item.data.some((value: any) => value > 0));
      default:
        return true;
        break;
    }
  }
  // Check if a column is in the clickableColumns based on its 'key'
  addClickableClass(object:any, columnKey: string): boolean {
    const column = this.clickableColumns.find(col => col.key === columnKey)
    return column && this.evaluateCondition(object, column.condition) ;
  }

  isClicked(object:any, columnKey: string): boolean {
    const column = this.clickableColumns.find(col => col.key === columnKey)
    return column && this.evaluateCondition(object, column.condition) ;
  }
  evaluateCondition(obj: any, conditions: any): boolean {
    return conditions.every((condition:any) => {
      switch (condition.comparison) {
        case 'greater':
          return obj[condition.property] > condition.value;
        case 'less':
          return obj[condition.property] < condition.value;
        case 'equal':
          return obj[condition.property] === condition.value;
        default:
          return false; // Immediately return false if unknown comparison type
      }
    });
    
  }
  onColumnClick(row: any, columnKey: any): void {
    let clickableC = this.clickableColumns.find(col => col.key === columnKey);
    this.cubejs.handleAction(clickableC, row, this.drilledDownQuery);
  }
  private formatChartLabel(label: string): string {
    if (label.includes(',')) {
      const labelParts = label.split(',');
      const datePart1 = labelParts[0].split('T');
      const datePart2 = datePart1[1].split('- ');
      const datePart = `${datePart1[0]} - ${datePart2[1]}`;
      return `${datePart}, ${labelParts[1]}`;
    }
    return label;
  }

  getTarget() {
    if (this.drilledDownQuery.HasTarget) {
      return this.cubejs.getChartTarget(this.drilledDownQuery).then(target => {
        // this.drilledDownQuery.OrganizationTarget = target.target[0].target;
        if (target.target[0] != undefined && target.target[0].target != 0)
          this.chartOptions!.plugins!.annotation = {
            annotations: {
              line1: {
                type: 'line',
                yMin: target.target[0].target,
                yMax: target.target[0].target,
                borderColor: 'rgb(255, 99, 132)',
                borderWidth: 2,
              }
            }
          }
      })
    }
    else {
      return Promise.resolve();
    }
  }
  setTarget(target: number) {

    if (this.drilledDownQuery.HasTarget && target != 0) {

      if (this.myChart && this.myChart.chart) {
        // Trigger a chart update
        this.myChart.chart.options!.plugins!.annotation = {
          annotations: {
            line1: {
              type: 'line',
              yMin: target,
              yMax: target,
              borderColor: 'rgb(255, 99, 132)',
              borderWidth: 2,
            }
          }
        }
        this.myChart.chart.update();
      }
    }
    else {
      if (this.myChart && this.myChart.chart) {
        this.myChart.chart.options!.plugins!.annotation = {
          annotations: {}
        };
        this.myChart.chart.update();
      }
    }
  }

  createGaugeChart() {
    const score = this.chartData[0]['data'][0];
    const canvas = document.getElementById('gaugeCanvas') as HTMLCanvasElement;
    const chartWidth: number = canvas.parentElement?.getBoundingClientRect().width!;
    const ctx = canvas?.getContext('2d');
    const gradientSegment = ctx?.createLinearGradient(0, 0, chartWidth, 0);
    gradientSegment?.addColorStop(0, 'red');
    gradientSegment?.addColorStop(0.6, 'yellow');
    gradientSegment?.addColorStop(1, 'green');
    this.gaugeChartData = {
      labels: ['Score', 'Grey Area'],
      datasets: [
        {
          data: [score, 100 - score], // Set the initial value here
          backgroundColor: [gradientSegment, 'rgba(235, 235, 235, 0.7)'], // Update background color
          borderColor: [gradientSegment], // Update border color
          borderWidth: [1, 0],
          cutout: '80%',
          circumference: 180,
          rotation: 270,

        }
      ]
    };
  }

  createWorldMapChart() {
    // Load GeoJSON data
    if (d3.select('#map-container').select('svg')) {
      d3.select('#map-container').select('svg').remove();
    }
    d3.json('../../assets/output.geojson').then((worldData: any) => {
      // Assuming that data is a dictionary with keys corresponding to GeoJSON feature IDs
      const data = this.chartData
      const width = this.width!;
      const height = 0.9 * this.height!;
      const tooltip = d3.select('#tooltip');

      this.projection = d3.geoMercator()
        .scale(150)
        .translate([width / 2, height / 2]);
      this.path = d3.geoPath().projection(this.projection);

      this.svg = d3.select('#map-container')
        .append('svg')
        .attr('width', width)
        .attr('height', height)
        .style('margin', "10px")
        .style("background-color", "#F6F8FB")
        .style("border-radius", "10px")
        .style("box-shadow", "inset 0 0 5px #D3DBE5")
        .style("transition", "box-shadow 0.3s ease");

      // Color scale
      const colorScale = d3.scaleLinear<string>()
        .domain([0, d3.max(Object.values(data.map((i: any) => i.count)) as number[])!])
        .range(['#D3DBE5', '#8ED0E3']);
      const path = this.path;
      const svg = this.svg;
      // Draw countries
      this.svg
        .selectAll('path')
        .data(worldData.features)
        .enter()
        .append('path')
        .attr('d', this.path)
        .attr('fill', (d: any) => {
          const countryId = d.properties.NAME.toUpperCase();
          let foundCountry: any = data.find((el: any) => el.country === countryId);
          const value = foundCountry ? foundCountry.count : 0; // Default to 0 if value is undefined
          return value > 0 ? colorScale(value) : '#D3DBE5'; // Use dark blue for countries with values, light blue otherwise
        })
        .attr('stroke', '#ffffff')
        .attr('stroke-width', 0.5)
        .on('mouseover', function (event: any, d: any) {
          d3.select(event.currentTarget).attr('fill', '#b5babf');

          const centroid = path.centroid(d);

          const countryId = d.properties.NAME.toUpperCase();
          let foundCountry: any = data.find((el: any) => el.country === countryId);
          if (foundCountry) {

            let quartersSet = new Set<string>();

            // Collect all quarters
            Object.keys(foundCountry.quarters).forEach(quarter => {
              quartersSet.add(quarter);
            });

            const quarters = Array.from(quartersSet).sort(); // Sort quarters if needed

            let tableHTML = `<table class="world-map-tooltip-table" border="1"><thead><tr><th>${foundCountry.country}</th>`;

            // Create headers for quarters
            quarters.forEach(quarter => {
              tableHTML += `<th>${quarter}</th>`;
            });

            tableHTML += '</tr></thead><tbody>';

            // Create rows for each data type (min, max, avg, count)
            ['Min', 'Max', 'Avg', 'Count'].forEach(type => {
              tableHTML += `<tr><td>${type}</td>`;
              quarters.forEach(quarter => {
                const data = foundCountry.quarters[quarter];
                if (data) {
                  switch (type) {
                    case 'Min':
                      tableHTML += `<td>${data.min}</td>`;
                      break;
                    case 'Max':
                      tableHTML += `<td>${data.max}</td>`;
                      break;
                    case 'Avg':
                      tableHTML += `<td>${data.avg.toFixed(2)}</td>`;
                      break;
                    case 'Count':
                      tableHTML += `<td>${data.count}</td>`;
                      break;
                  }
                } else {
                  tableHTML += '<td>N/A</td>'; // In case some quarters are missing
                }
              });
              tableHTML += '</tr>';
            });

            tableHTML += '</tbody></table>';

            // Calculate the position of the tooltip relative to the map container
            const mapContainerRect = document.getElementById('map-container')?.getBoundingClientRect();
            const tooltipWidth = 100;
            const tooltipHeight = 50;
            if (mapContainerRect) {
              const tooltipLeft = Math.min(mapContainerRect.width - tooltipWidth, Math.max(0, centroid[0] - (tooltipWidth / 2))); // Center the tooltip horizontally and prevent overflow
              const tooltipTop = Math.min(mapContainerRect.height - tooltipHeight, Math.max(0, centroid[1] - tooltipHeight - 10)); // Position the tooltip above the country and prevent overflow
              tooltip.style('visibility', 'visible');
              tooltip.html(tableHTML)
                .style('left', tooltipLeft + 'px')
                .style('top', tooltipTop + 'px');
            }
          }



        })
        .on('mouseout', (event: any, d: any) => {
          const countryId = d.properties.NAME.toUpperCase();
          let foundCountry: any = data.find((el: any) => el.country === countryId);
          const value = foundCountry ? foundCountry.count : 0; // Default to 0 if value is undefined
          const fillColor = value > 0 ? colorScale(value) : '#D3DBE5'; // Use dark blue for countries with values, light blue otherwise
          d3.select(event.currentTarget).attr('fill', fillColor); // Revert fill color on mouseout

          tooltip.style('visibility', 'hidden');

        });
      svg.selectAll('g.icon-group')
        .data(worldData.features.filter((d: any) => {
          const countryData: any = data.find((el: any) => el.country === d.properties.NAME.toUpperCase());
          return countryData && countryData.count > 0;
        }))
        .enter()
        .append('g')
        .attr('class', 'icon-group')
        .each(function (d: any, i: number, group: any) {
          const centroid = path.centroid(d);
          let foundCountry: any = data.find((el: any) => el.country === d.properties.NAME.toUpperCase());
          const value = foundCountry ? foundCountry.count : 0;

          d3.select(group[i])
            .append('image')
            .attr('xlink:href', 'assets/location_on_FILL1_wght400_GRAD0_opsz24.svg')
            .attr('width', 25)
            .attr('height', 25)
            .attr('x', centroid[0] - 10) // Adjust x-coordinate based on icon size
            .attr('y', centroid[1] - 10) // Adjust y-coordinate based on icon size
            .style('pointer-events', 'none');
          // Append a badge (circle) to the icon
          const badge = d3.select(group[i])
            .append('g')
            .attr('class', 'badge');

          badge.append('circle')
            .attr('cx', centroid[0] + 7) // Adjust x-coordinate based on badge position
            .attr('cy', centroid[1] - 7) // Adjust y-coordinate based on badge position
            .attr('r', 10) // Adjust radius of the badge
            .attr('fill', 'red'); // Set fill color of the badge

          badge.append('text')
            .attr('x', centroid[0] + 7) // Adjust x-coordinate based on badge position
            .attr('y', centroid[1] - 7) // Adjust y-coordinate based on badge position
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'middle')
            .attr('font-size', '8px')
            .attr('fill', 'white')
            .text(value); // Display the value inside the badge
        });

      const zoom: any = d3.zoom()
        .scaleExtent([1, 8])
        .on('zoom', (event) => {
          this.svg.selectAll('path')
            .attr('transform', event.transform)
            .attr('stroke-width', 0.5 / event.transform.k); // Adjust stroke width for zoom level

          // Update icon positions and badge positions on zoom
          this.svg.selectAll('g.icon-group')
            .attr('transform', event.transform)
            .select('.badge')
            .attr('r', 10 / event.transform.k) // Adjust badge size based on zoom level
            .select('circle')
            // .attr('r', 8 / event.transform.k) // Adjust badge size based on zoom level
            // .select('text')
            .attr('font-size', `${event.transform.k}px`); // Adjust font size based on zoom level
        });


      this.svg.call(zoom);
    }).catch(err => {
      console.log('Error loading world data:', err);
    });

  }

  public chartClicked(event: any): void {
    const activePoints = event.active || [];
    const hasDrill = this.drilledDownQuery.DrillQueryId === null ? false : true;
    this.drillDown(hasDrill, activePoints)
  }

  drillDown(hasDDQuery: boolean, activePoints: any): void {
    this.isLoadingChart = true;
    let pivotConfig: any = this.drilledDownQuery.Configs.find(el => el.Code === this.visualCode)!.QueryPivotConfig;
    if (activePoints.length > 0) {
      if (hasDDQuery) {
        const value = this.chartLabelsLong[activePoints[0].index];
        const label = pivotConfig.x[0]//this.chartData[activePoints[0].datasetIndex]["yValue"][0][0];
        this.drillFilters.push(...this.filters);
        this.drillFilters.push({
          [label]: [value]
        });
        this.cubejs.getNewQueryObject(this.drilledDownQuery.DrillQueryId!).subscribe(response => {
          this.drilledDownQuery = response;
          this.cubejs.getChartData(this.drilledDownQuery, this.visualCode, this.drillFilters, this.organizationId).then((dt: any) => {
            let pivotConfig: any = this.drilledDownQuery.Configs.find(el => el.Code === this.visualCode)!.QueryPivotConfig;
            const drillDownLabel = pivotConfig.x[0] || value;
            let newNode: TreeNode = {
              parent: response,
              level: this.currentDrillNode!.level + 1,
              filterName: label,
              children: [],
              filterby: drillDownLabel
            };
            const index = this.pushNodeToLevel(this.drillHierarchy, newNode, this.currentDrillNode?.level);
            const translatedHeader = this.cubejs.findTranslation(newNode.filterby, newNode.parent.Id)
            this.data.Name = this.data.Name + '/' + translatedHeader
            this.currentDrillNode = this.getNextNode();
            if (this.visualCode == VisualType.Table) {
              let dataTable = this.cubejs.translateColumns(dt.result, this.tableColumns, undefined);
              this.tableColumns = dataTable.columns;
              this.tableColumnsNames = dataTable.columns.map((column: any) => column.name);
              this.tableDatasource.data = dataTable.data;
              this.tableDatasource = new MatTableDataSource<any>(dataTable.data);
              this.tableDatasource.paginator = this.tableMatPaginator;
              this.tableDatasource.sort = this.tableMatSort;
              const meta = dt.meta;
              this.tableColumns.forEach((tableColumn: any) => {
                const columnMeta = meta.find((column: any) => column.key === tableColumn.key);

                if (columnMeta && columnMeta.meta) {
                  // Add a 'description' property to the column
                  tableColumn.description = columnMeta.meta.description;
                }
              });
            }
            else if (this.visualCode == VisualType.NumberCard) {
              if (dt.result[0] === null)
                this.mainValueCard = 0
              else this.mainValueCard = dt.result[0].numericValues;
              this.descriptionCard = dt.result[0].yValue;
              this.resultSet = dt.data;
              const meta = dt.meta;
              this.format = meta[0].format;
              if (meta[0].key == this.descriptionCard[0] && meta[0].meta) {
                // Use the title or any other property from meta as the tooltip content
                this.descriptionCardTooltip = meta[0].meta.description;
              }
            }
            else if (this.visualCode == VisualType.Gauge) {
              this.chartType = dt.result['chartType'];
              this.chartLabels = dt.result['chartLabels'];
              this.chartData = dt.result['chartData'];
              this.resultSet = dt.data;
              this.createGaugeChart();
            }
            else {
              this.chartType = dt.result['chartType'];
              this.chartLabels = dt.result['chartLabels'].map((label: any) => label.short);
              this.chartLabelsLong = dt.result['chartLabels'].map((label: any) => label.long);
              this.chartData = dt.result['chartData'];
              this.resultSet = dt.data;
            }
            this.showDrilUp = true;
            this.isLoadingChart = false;
          });
        });
      }
      else {
        this.isLoadingChart = false;
      }
    }
    else {
      let chart, value: any, label: any;
      if (this.visualCode == this.VisualTypes.NumberCard) {
        value = this.xValue;
        label = this.descriptionCard[0];
      }
      else {
        if (this.visualCode == this.VisualTypes.Gauge) {
          value = this.chartData[0]['data'];
          label = this.chartData[0]['yValue'][0];

        }
        else {
          value = this.chartLabelsLong[activePoints[0].index];
          label = this.chartData[activePoints[0].datasetIndex]["yValue"][0][0]//chart._metasets[0]._dataset.yValue[0];
        }
      }
      let drillDownQuery = this.resultSet.drillDown({
        xValues: [value],
        yValues: label
      }, pivotConfig);
      if (drillDownQuery) {
        this.cubejs.getCubeJSData(drillDownQuery).then(response => {
          let result = response.tablePivot();
          const dialogRef = this.dialog.open(DrillDownDialog, {
            width: '50%',
            data: { table: result, description: this.drilledDownQuery.Description, query: undefined},
          });
          this.isLoadingChart = false;
        })
      }
      else this.isLoadingChart = false;
    }
  }
  pushNodeToLevel(nodes: TreeNode[], newNode: TreeNode, level: number | undefined): boolean {
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      if (node.level === level) {
        if (!node.children) {
          node.children = [];
        }
        node.children.push(newNode);
        return true;  // Node with level 1 found, and new node pushed
      }

      // Recursively search in children, if present
      if (node.children && this.pushNodeToLevel(node.children, newNode, level)) {
        return true;  // Node found and new node pushed in children
      }
    }

    return false;  // Node with level 1 not found
  }
  drillUp(): void {
    this.isLoadingChart = true;
    if (this.currentDrillNode!.level !== 0) {
      this.drillFilters = this.drillFilters.filter(filter => !(this.currentDrillNode!.filterName in filter));
      this.currentDrillNode = this.getParentNode(this.drillHierarchy, this.currentDrillNode!.level - 1);
      this.drilledDownQuery = this.currentDrillNode.parent;
      const nameParts = this.data.Name.split('/');
      nameParts.pop();
      this.data.Name = nameParts.join('/');
      this.cubejs.getChartData(this.currentDrillNode.parent, this.visualCode, this.drillFilters, this.organizationId).then((dt: any) => {
        if (this.visualCode == VisualType.Table) {
          let dataTable = this.cubejs.translateColumns(dt.result, this.tableColumns, undefined);
          this.tableColumns = dataTable.columns;
          this.tableColumnsNames = dataTable.columns.map((column: any) => column.name);
          this.tableDatasource.data = dataTable.data;
          this.tableDatasource.paginator = this.tableMatPaginator;
          this.tableDatasource.sort = this.tableMatSort;
          const meta = dt.meta;
          this.tableColumns.forEach((tableColumn: any) => {
            const columnMeta = meta.find((column: any) => column.key === tableColumn.key);

            if (columnMeta && columnMeta.meta) {
              // Add a 'description' property to the column
              tableColumn.description = columnMeta.meta.description;
            }
          });
        }
        else if (this.visualCode == VisualType.NumberCard) {
          if (dt.result[0] === null)
            this.mainValueCard = 0
          else this.mainValueCard = dt.result[0].numericValues;
          this.descriptionCard = dt.result[0].yValue;
          this.resultSet = dt.data;

          const meta = dt.meta;
          this.format = meta[0].format;
          if (meta[0].key == this.descriptionCard[0] && meta[0].meta) {
            // Use the title or any other property from meta as the tooltip content
            this.descriptionCardTooltip = meta[0].meta.description;
          }
        }
        else if (this.visualCode == VisualType.Gauge) {
          this.chartType = dt.result['chartType'];
          this.chartLabels = dt.result['chartLabels'];
          this.chartData = dt.result['chartData'];
          this.resultSet = dt.data;
          this.createGaugeChart();
        }
        else {
          this.chartType = dt.result['chartType'];
          this.chartLabels = dt.result['chartLabels'].map((label: any) => label.short);
          this.chartLabelsLong = dt.result['chartLabels'].map((label: any) => label.long);
          this.chartData = dt.result['chartData'];
          this.resultSet = dt.data;
        }
        this.showDrilUp = this.currentDrillNode?.level == 0 ? false : true;
        this.isLoadingChart = false;
      });
    }
  }
  getNextNode(): TreeNode | undefined {
    const children = this.currentDrillNode!.children;
    return children && children.length > 0 ? children[0] : undefined;
  }

  getParentNode(tree: TreeNode[], level: number): TreeNode {
    for (const node of tree) {
      if (node.children) {
        for (const child of node.children) {
          if (child.level === level) {
            return child;
          }
        }
      }
    }
    return tree[0]; // Return the root if not found
  }
  calculateFontSize(width: number | undefined, height: number | undefined) {
    return this.cubejs.calculateFontSize(width, height, 0.15, 130, 50, '%')
  }

  openDialog(comment: any): void {
    const dialogRef = this.dialog.open(CommentDialog, {
      width: '70%',
      data: comment,
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        let factoryList
        switch (this.data.Name) {
          case 'Otto Activity Monitoring': factoryList = this.tableDatasource.data.filter(r => r['Bhive ID'] == result.supplierId); break;
          case "Quarterly Inventory Overview": case "Monthly Inventory Overview": factoryList = this.tableDatasource.data.filter(r => r['Formulas.organization_id'] == result.supplierId); break;
          case "Higg Overview": case "Wastewater Overview": case "Wastewater Test Reports(v2.1) - Result Analysis": factoryList = this.tableDatasource.data.filter(r => r['Factories.organization_id'] == result.supplierId); break;
        }
        if (factoryList) {
          factoryList.forEach((el: any) => {
            el.hasComment = true;//set flag hasComment to true, used to color the icon
            el.comment['supplierName'] = result['supplierName'];
            el.comment['supplierId'] = result['supplierId'];
            el.comment['commentDetails'].push({//set comment history in an array
              createdOn: result['createdOn'],
              userName: result['userName'],
              comment: result['comment']
            });
            el.queryId = result['queryId'];
          })
        }
      }
    });
  }
}





