import { Pipe, PipeTransform } from '@angular/core';
import { valueOrDefault } from 'chart.js/dist/helpers/helpers.core';

@Pipe({
  name: 'conditionalNumberFormat'
})
export class ConditionalNumberFormatPipe implements PipeTransform {

  transform(value: any): any {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        const firstElement = value[0];
        return this.formatNumber(firstElement);
      }
    } else {
      if (typeof value === 'number') {
        return this.formatNumber(value);
      }
      else if (this.isValidDate(value) && !this.isNumberLike(value)) {
        // Check if the value matches the "YYYY-MM" format
        if (/^\d{4}-\d{2}$/.test(value)) {
          return value; // Return as-is
        }
        const originalDate = new Date(value);

        const day = originalDate.getDate().toString().padStart(2, '0');
        const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const year = originalDate.getFullYear();

        const formattedDateString = `${year}-${month}-${day}`;
        return formattedDateString;
      }
      else {
        if (value == null)
          return value
        else {
          const parsedDate = new Date(value)
          if (!isNaN(parsedDate.getTime())) {
            const originalDate = new Date(value);

            // Return formatted date in YYYY-MM format
            const year = originalDate.getFullYear();
            const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');

            return `${year}-${month}`;
          }
          else return value;
        }

      }
    }
    return value;

  }

  private isValidDate(dateString: string | null | undefined): boolean {
    if (!dateString) {
      return false;
    }
    // Check if there are any alphabetic characters in the string
    if (/[a-zA-Z]/.test(dateString)) {
      return false;
    }
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  }

  isNumberLike(value: any) {
    return !isNaN(value) && !isNaN(parseFloat(value));
  }

  private formatNumber(value: number): any {
    const hasDecimalPart = value % 1 !== 0;
    return hasDecimalPart ? value.toFixed(1) : value;
  }
}