import { NgModule } from '@angular/core';
import { BhiveDashboardComponent, CustomDateFormat1, CustomDateFormat2, FilterPipe } from './bhive-dashboard.component';
import { GridsterModule } from 'angular-gridster2';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BhiveDashboardItemComponent, CommentDialog } from './bhive-dashboard-item.component';
import { BhiveDashboardService } from './bhive-dashboard.service';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { SaveDialog } from './confirm-dialog.component';
import { CompareDialog } from './compare-dialog.component';
import { BhiveDashboardBaseComponent } from './bhive-dashboard-base.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { NgChartsModule } from 'ng2-charts'
import { DrillDownDialog } from './drilldown-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BhiveLoadingComponent } from './bhive-loading.component';
import { DecimalPipe } from '@angular/common';
import { ConditionalNumberFormatPipe } from './conditional-number-format.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { FilterItemDirective } from './filter-item.directive';
import { TableFilterHeadersComponent } from './table-filter-headers/table-filter-headers.component';
import { MatInputModule } from '@angular/material/input';
import { MatRippleModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMomentDateModule } from '@angular/material-moment-adapter';


// import { BhiveLoadingComponent } from './bhive-loading.component';
// import { HTTP_INTERCEPTORS } from '@angular/common/http';
// import { LoadingInterceptor } from './bhive-loader.interceptor';
@NgModule({
  declarations: [
    BhiveDashboardBaseComponent,
    BhiveDashboardComponent,
    BhiveDashboardItemComponent,
    CommentDialog,
    SaveDialog,
    CompareDialog,
    DrillDownDialog,
    FilterPipe,
    BhiveLoadingComponent,
    ConditionalNumberFormatPipe,
    FilterItemDirective,
    TableFilterHeadersComponent,
    CustomDateFormat1,
    CustomDateFormat2
    // BhiveLoadingComponent
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    FormsModule,
    GridsterModule,
    MatSelectModule,
    MatSidenavModule,
    MatButtonToggleModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    MatToolbarModule,
    MatSliderModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSortModule,
    MatPaginatorModule,
    MatStepperModule,
    MatTableModule,
    MatMenuModule,
    MatDialogModule,
    MatCardModule,
    NgChartsModule,
    DragDropModule,
    MatTooltipModule,
    MatListModule,
    MatInputModule,
    MatRippleModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatMomentDateModule,
    
  ],
  providers: [BhiveDashboardService, DecimalPipe
  //   {
  //   provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
  // }
],
  exports: [
    BhiveDashboardBaseComponent
  ]
})
export class BhiveDashboardModule { }
