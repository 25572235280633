import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of, Subscriber } from 'rxjs';
import { delay, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class FormulaService {


  myAllChemicalList: any = undefined;

  constructor(
    private http: HttpClient,

  ) { }
  clearChemstore() {
    this.myAllChemicalList = undefined
  }
  getDocumentsAllFormulas(orgId) {
    return this.http.get(environment.baseHref + `/getDocumentsAllFormulas/` + orgId);
  }
  searchFormulaByName(name) {
    return this.http.get<any[]>(environment.baseHref + '/formulas/search?max=25&q=' + name);
  }
  searchFormulaByPID(pid) {
    return this.http.get(environment.baseHref + '/formulas/searchPID?max=25&q=' + pid);
  }
  searchFormulaByOcrKey(ocrKey) {

    return this.http.get(environment.baseHref + '/formulas/search-by-ocr?ocrResult=' + ocrKey);
  }

  getAllChemicalList(orgId) {
    return new Observable((observer) => {
      if (this.myAllChemicalList != undefined && this.myAllChemicalList.content.length > 0) {
        observer.next(this.myAllChemicalList);
        observer.complete();
      } else {
        this.http.get(environment.baseHref + `/getAllFormulaZDHC/` + orgId).subscribe((data: any[]) => {
          this.myAllChemicalList = data;
          observer.next(this.myAllChemicalList);
          observer.complete();
        });

      }
    })


  }

  // getFormulaType(formula) {
  //   if (formula.masterID && formula.masterID != '-1') {
  //     return 'F'
  //   } else {
  //     return 'M'
  //   }
  // }

  getFormulaVerifiedStatus(formula) {
    let verifiedFormula = false;
    let type = this.getFormulaType(formula)

    if (type == 'M') {
      verifiedFormula = formula['bluwinSdsVerified'] === 'Yes' || formula['testReport'] === 'Yes' || formula['gatewayLevel'] != null && (formula['gatewayLevel'].includes('v2') || formula['gatewayLevel'].includes('v3'))
    } else {
      verifiedFormula = formula['bluwinSdsVerified'] === 'Yes' || formula['testReport'] === 'Yes' || formula['zdhcMrsl'] == 'Yes'
        || formula['c2c'] == 'Yes'
        || formula['gatewayLevel'] != null && (formula['gatewayLevel'].includes('v2') || formula['gatewayLevel'].includes('v3'))
    }
    return verifiedFormula;

  }

  getFormulaType(formula) {
    if (formula.masterID && formula.masterID != '-1') {
      return 'F'
    } else if (formula.masterid && formula.masterid != '-1') {
      return 'F'
    } else {
      return 'M'
    }
  }

}
